export const Columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Case ID",
    accessor: "case_id",
  },
  {
    Header: "Opp ID",
    accessor: "oppId",
  },
  {
    Header: "Contact ID",
    accessor: "contact_id",
  },
  {
    Header: "Contact ID",
    accessor: "preferred_language",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Sub Type",
    accessor: "sub_type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Date",
    accessor: "date_entered",
  },
];
