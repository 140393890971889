import React, { useEffect, useState, useContext } from "react";
import RequestedDocumentsList from "./requested-documents/RequestedDocumentsList";
import AllDocumentsList from "./all-documents/AllDocumentsList";
import "../../styles/CaseDocuments.css";

const CaseDocuments = ({
  caseNo,
  caseID,
  oppId,
  contactID,
  status,
  serviceType,
  serviceSubType,
  preferredLanguage,
}) => {
  const [selectedCaseDocumentsType, setSelectedCaseDocumentsType] = useState(
    "Requested Documents"
  );

  const caseDocumentsType = ["All Documents", "Requested Documents"];

  const changeSelectedCaseDocumentsType = (e) => {
    setSelectedCaseDocumentsType(e.target.value);
  };

  return (
    <div className="case-doc-content">
      {selectedCaseDocumentsType === "All Documents" ? (
        <AllDocumentsList
          caseNo={caseNo}
          caseID={caseID}
          oppId={oppId}
          caseDocumentsType={caseDocumentsType}
          selectedCaseDocumentsType={selectedCaseDocumentsType}
          changeSelectedCaseDocumentsType={changeSelectedCaseDocumentsType}
          preferredLanguage={preferredLanguage}
        />
      ) : (
        <div>
          <RequestedDocumentsList
            caseNo={caseNo}
            caseID={caseID}
            contactID={contactID}
            status={status}
            serviceType={serviceType}
            serviceSubType={serviceSubType}
            caseDocumentsType={caseDocumentsType}
            selectedCaseDocumentsType={selectedCaseDocumentsType}
            changeSelectedCaseDocumentsType={changeSelectedCaseDocumentsType}
          />
        </div>
      )}
    </div>
  );
};

export default CaseDocuments;
