import React from "react";
import TextField from "@mui/material/TextField";

const InputUI = ({
  name,
  label,
  type,
  value,
  setValue,
  variant,
  fullWidth,
  required,
  cssStyles,
  register,
}) => {
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      autoComplete="off"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      style={cssStyles}
      {...register(name)}
    />
  );
};

export default InputUI;
