import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import permanentlyDeleteDocument from "../../../api/permanentlyDeleteDocument";
import { AuthContext } from "../../../context/Auth/AuthContext";
import ButtonUI from "../../material-ui/ButtonUI";

const PermanentlyDeleteDocument = ({ setDocumentDeletedToTrue, id }) => {
  const [open, setOpen] = useState(false);
  const { token } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPermanentDeleteDocument = async () => {
    const permanentDeleteDocumentFormResponse = await permanentlyDeleteDocument(
      {
        token: token,
        id: id,
      }
    );

    if (permanentDeleteDocumentFormResponse.code === 200) {
      setOpen(false);
      setDocumentDeletedToTrue();
      toast.success("Document is deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdDelete />}
        style={{
          backgroundColor: "#dc3545",
          color: "white",
          padding: "7px",
        }}
        onClickFunc={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <MdDelete />
          </div>
          <div className="popup-header-title">Permanently Delete Document</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently delete this document ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            onClickFunc={onPermanentDeleteDocument}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PermanentlyDeleteDocument;
