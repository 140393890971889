import React, { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const PdfDetails = ({ pdfData, documentID, documentType }) => {
  const [isOpen, setIsOpen] = useState(true);
  const togglePdfDetailsVisibility = () => setIsOpen(!isOpen);
  return (
    <div className="document-details-container">
      <div
        className="document-details-header"
        onClick={togglePdfDetailsVisibility}
      >
        <div className="document-details-title">Document Details</div>
        <div className="document-details-visibility-icon">
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
      </div>
      <div>
        {isOpen ? (
          <div className="document-details-content">
            <div>
              <span className="document-details-data">Document ID</span>
              <span className="document-details-data-value">{documentID}</span>
            </div>
            <div>
              <span className="document-details-data">Document Type</span>
              <span className="document-details-data-value">
                {documentType}
              </span>
            </div>
            <div>
              <span className="document-details-data">Document Size</span>
              <span className="document-details-data-value">
                {pdfData?.pdfsize || "NA"}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PdfDetails;
