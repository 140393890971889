import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import BulkLinkPopup from "./BulkLinkPopup";

const Dropdown = ({
  caseNo,
  caseID,
  selectedRows,
  setCategoryUpdatedToTrue,
}) => {
  const [open, setOpen] = useState(false);

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown-button">
      <IoMdArrowDropdown
        onClick={changeDropdownVisibilityFunc}
        style={{ visibility: selectedRows.length > 0 ? "visible" : "hidden" }}
      />
      <div
        className="doc-elements"
        style={{
          display: open ? "block" : "none",
          zIndex: 10,
          marginTop: "3px",
        }}
      >
        <ul className="docs-dropdown-list">
          <li className="docs-dropdown-item">
            <BulkLinkPopup
              caseNo={caseNo}
              caseID={caseID}
              selectedRows={selectedRows}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
