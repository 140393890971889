import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";

const EditDocumentForm = ({
  register,
  control,
  errors,
  tableValues,
  categoryOptions,
}) => {
  return (
    <Grid className="edit-document">
      <div className="edit-document-form">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="caseID"
                defaultValue={tableValues.case_number}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="caseID"
                    label="Case ID"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    disabled={true}
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">{errors["caseID"]?.message}</span>
          </div>
          <div className="field">
            <div className="input-field">
              <Controller
                name="documentTitle"
                defaultValue={tableValues.document_title}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="documentTitle"
                    label="Document Title"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    cssStyles={{ marginTop: "20px" }}
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["documentTitle"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="documentDescription"
                defaultValue={tableValues.document_desc}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="documentDescription"
                    label="Document Description"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    cssStyles={{ marginTop: "20px" }}
                    register={register}
                  />
                )}
              />
            </div>

            <span className="error-message">
              {errors["documentDescription"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="edit-doc-select-option" fullWidth>
              <InputLabel style={{ marginTop: "20px" }} id="select-label">
                Category
              </InputLabel>
              <Controller
                control={control}
                name="category"
                defaultValue={tableValues.name}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="category"
                    sx={{ marginTop: "20px" }}
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Category"
                    {...register("category")}
                  >
                    {categoryOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["category"]?.message}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditDocumentForm;
