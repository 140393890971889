import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FiEdit } from "react-icons/fi";
import { FaSave } from "react-icons/fa";
import updateRequestedDocumentCategory from "../../../api/updateRequestedDocumentCategory";
import { AuthContext } from "../../../context/Auth/AuthContext";

const CategoryCell = ({ value, docID, setCategoryUpdatedToTrue }) => {
  const [category, setCategory] = useState("data");
  const [selectedOption, setSelectedOption] = useState(value);

  const { token } = useContext(AuthContext);

  const onSaveCategoryBtnClick = async () => {
    const saveCategory = await updateRequestedDocumentCategory({
      token: token,
      docID: docID,
      category: selectedOption,
    });

    if (saveCategory.code === 200) {
      setCategoryUpdatedToTrue();
      setCategory("data");
      toast.success(
        "Requested document category has been updated successfully",
        {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <>
      {category === "select" ? (
        <div className="case-doc-table-category-cell">
          <FormControl className="category-cell-doc-select-ui">
            <InputLabel
              className="category-cell-doc-select-label"
              id="select-label"
            >
              Category
            </InputLabel>
            <Select
              name="category"
              sx={{ margin: "10px 0px" }}
              labelId="select-label"
              variant="outlined"
              id="simple-select"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              label="Category"
            >
              <MenuItem value={value}>{value}</MenuItem>
              <MenuItem
                value={value === "Recommended" ? "Required" : "Recommended"}
              >
                {value === "Recommended" ? "Required" : "Recommended"}
              </MenuItem>
            </Select>
          </FormControl>
          <span
            className="case-doc-table-category-cell-icon"
            onClick={onSaveCategoryBtnClick}
            style={{ color: "#28a745" }}
          >
            <FaSave />
          </span>
        </div>
      ) : (
        <div className="case-doc-table-category-cell">
          <span className="case-doc-table-category-cell-value">{value}</span>
          <span
            className="case-doc-table-category-cell-icon"
            onClick={() => setCategory("select")}
            style={{ color: "#17a2b8" }}
          >
            <FiEdit />
          </span>
        </div>
      )}
    </>
  );
};

export default CategoryCell;
