import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import BulkDeletePopup from "./BulkDeletePopup";

const Dropdown = ({
  selectedRows,
  isEntireTableRowsSelected,
  setDocumentDeletedToTrue,
}) => {
  const [open, setOpen] = useState(false);
  const [docId, setDocId] = useState();

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  const handleDeleteRows = (selectedRows) => {
    setDocId(selectedRows);
  };

  return (
    <div className="dropdown-button">
      <IoMdArrowDropdown
        onClick={changeDropdownVisibilityFunc}
        style={{ visibility: selectedRows.length > 0 ? "visible" : "hidden" }}
      />
      <div
        className="doc-elements"
        style={{
          display: open ? "block" : "none",
          zIndex: 10,
          marginTop: "3px",
        }}
      >
        <ul className="docs-dropdown-list">
          <li
            className="docs-dropdown-item"
            onClick={() => handleDeleteRows(selectedRows)}
          >
            <BulkDeletePopup
              setDocumentDeletedToTrue={setDocumentDeletedToTrue}
              isEntireTableRowsSelected={isEntireTableRowsSelected}
              changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
              Docbulkid={docId}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
