import { BsFillFileEarmarkPlayFill } from "react-icons/bs";

export const Columns = [
  {
    Header: "id",
    accessor: "doc_id",
  },
  {
    Header: "Status",
    accessor: "status_c",
  },
  {
    Header: "Download Link",
    accessor: "download_link_c",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Category",
    accessor: "document_category_c",
  },
  {
    Header: "Date",
    accessor: "date_entered",
  },
];
